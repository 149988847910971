.hero-container {
  display: flex;
  height: 90vh;
  justify-content: space-between;
  margin: 0;
  padding: 1em;
  min-height: 0;
  overflow: auto;
}

.hero-container-mobile {
  flex-direction: column;
}

.image-container {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  flex: 1;
}

.image-container-mobile {
  height: 50vh;
  flex: none;
}

.text-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  padding-right: 3em;
  min-height: 0;
  max-height: 50vh;
}

.text-container-mobile {
  height: 50vh;
  flex: none;
}

@media (max-width: 768px) {
    .hero-container {
      flex-direction: column;
      padding: 0em;
      justify-content: flex-start;
      height: auto;
    }
  
    .image-container {
      height: 50vh;
      flex: none;
    }
  
    .text-container {
      height: auto;
      flex: none;
      padding-right: 0em;
   
    }
  }