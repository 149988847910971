.aboutmeimage-desktop {
  display: none; /* Hide on mobile */
}

.aboutmeimage-mobile {
  display: block; /* Show on mobile */
  
}

@media screen and (min-width: 769px) {
  .aboutmeimage-desktop {
    display: block; /* Show on desktop */
  }

  .aboutmeimage-mobile {
    display: none; /* Hide on desktop */
  }
}
.container {
  display: grid;
  grid-template-areas: 
    "image"
    "text";
}

.aboutmeimage {
  grid-area: image;
}

.text {
  grid-area: text;
}

@media screen and (min-width: 769px) {
  .container {
    grid-template-areas: "text image"; /* On desktop, display side by side */
  }
}

@media screen and (max-width: 768px) {
  .container {
    grid-template-areas: 
      "image"
      "text";
  }
}