.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

form {
  position: absolute; /* Set the position of the form to relative */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 40px; /* Make the form bigger */
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background-color: #f5f5dc; /* Change the background color to beige */
}


.form-input {
  width: 300px; /* Make the input fields wider */
  padding: 15px; /* Increase the padding */
  border-radius: 5px;
  border: 1px solid #ddd;
  outline: 1px solid #ddd;
}

.form-submit {
  width: 150px; /* Make the submit button wider */
  padding: 15px; /* Increase the padding */
  border-radius: 5px;
  border: none;
  color: white;
  background-color: #8b4513; /* Change the button color to a darker shade of beige */
  cursor: pointer;
}

.form-submit:hover {
  background-color: #5a2c10; /* Darken the button color on hover */
}

.close-button {
  position: absolute;
  top: 0px;
  right: 10px;
  bottom: 0x;
  border: none;
  background-color: transparent;
  font-size: 30px; /* Make the close button bigger */
  color: #8b4513; /* Change the close button color to a darker shade of beige */
  cursor: pointer;
}